import React, { useState, useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import { Checkbox } from "antd";
import useColorTheme from "../../../hooks/useColorTheme";
import { Popover } from "antd";
import about from "assets/images/about.svg";
import { inactiveMetrics, activeMetrics } from "components/Alerts/alertTemplate";

const MetricsAlert = ({
  item,
  selectItems,
  setMetricsParamsSlider,
  setMetricsParamsCheckbox,
  isMetric,
  setIsCheckMetric,
  toggleMetricsParams,
  displayAsCheckbox = false,
}) => {
  const { primaryColor } = useColorTheme();
  const { name, label, aboutInfo, section } = item;
  const wrapperRef = useRef(null);
  const { sections } = useSelector((state) => state.authorization);
  const [openDropdown, setOpenDropdown] = useState(false);

  const handleChangeMetric = () => {
    if (displayAsCheckbox) {
      toggleMetricsParams(name, isMetric ? inactiveMetrics[name] : activeMetrics[name]);
      setIsCheckMetric(name, !isMetric);
    } else {
      if (!isMetric) {
        setOpenDropdown(true);
        toggleMetricsParams(name, activeMetrics[name]);
      } else {
        setOpenDropdown(false);
        toggleMetricsParams(name, activeMetrics[name]);
      }
      setIsCheckMetric(name, !isMetric);
    }
  };

  const resetAllParams = () => {
    toggleMetricsParams(name, inactiveMetrics[name]);
  };

  function useOutsideAlerter(ref) {
    useEffect(() => {
      if (openDropdown) {
        function handleClickOutside(event) {
          if (ref.current && !ref.current.contains(event.target)) {
            setOpenDropdown(false);
          }
        }
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
          document.removeEventListener("mousedown", handleClickOutside);
        };
      }
    }, [ref, openDropdown]);
  }
  useOutsideAlerter(wrapperRef);

  const isCheckboxChecked = (checkboxName) => {
    return selectItems[checkboxName] === true;
  };

  return (
    <div ref={wrapperRef} className="wrapper-custom-select-box">
      <div className="wrapper-custom-select">
        <Checkbox
          style={{ backgroundColor: openDropdown ? "#d9dfe6" : "#eef2f7" }}
          disabled={
            name !== "listing" &&
            !(
              sections.find((item) => item.name === section) || {
                status: sections.find((item) => item.name === "availability").status,
              }
            ).status
          }
          checked={isMetric}
          onChange={handleChangeMetric}
        >
          <div className="label">{label}</div>
          <div className="hint-selected-item">
            {aboutInfo && (
              <Popover content={<div style={{ whiteSpace: "pre-wrap" }}>{aboutInfo}</div>}>
                <img src={about} alt="Icon" />
              </Popover>
            )}
          </div>
        </Checkbox>
      </div>
      {item.name !== "stock" ? (
        <button
          style={{ color: isMetric ? primaryColor : "#9696a0" }}
          className="set-details"
          disabled={!isMetric}
          onClick={() => setOpenDropdown(true)}
        >
          Set Details
        </button>
      ) : (
        <button style={{ visibility: "hidden" }} className="set-details" disabled>
          Set Details
        </button>
      )}
      {openDropdown && selectItems && !displayAsCheckbox ? (
        <div className="custom-select-dropdown">
          <div className="slider-wrapper">
            {item.hasOwnProperty("title") ? <div className="title">{item.title}</div> : null}
            <div style={{ marginBottom: "16px" }}>
              {item?.checkbox?.map((checkboxItem, index) => (
                <div key={index} style={{ marginBottom: "16px" }}>
                  {!checkboxItem.hide && (
                    <checkboxItem.component
                      label={checkboxItem.label}
                      value={
                        checkboxItem.subName
                          ? selectItems[checkboxItem.name][checkboxItem.subName]
                          : selectItems[checkboxItem.name]
                      }
                      setValue={(value) =>
                        setMetricsParamsCheckbox(name, checkboxItem.name, checkboxItem.subName, value)
                      }
                    />
                  )}
                  {/* Display sliders that depend on this checkbox */}
                  {item?.slider
                    ?.filter((sliderItem) => sliderItem.dependsOn?.includes(checkboxItem.name))
                    .map(
                      (sliderItem, sliderIndex) =>
                        isCheckboxChecked(checkboxItem.name) && (
                          <div key={sliderIndex} style={{ marginTop: "8px", marginBottom: "24px" }}>
                            <div className="slider-value">{sliderItem.label}</div>
                            <sliderItem.component
                              values={selectItems[sliderItem.name]}
                              setValues={(values) => setMetricsParamsSlider(name, sliderItem.name, values)}
                            />
                          </div>
                        )
                    )}
                </div>
              ))}
            </div>
            {item?.slider
              ?.filter((sliderItem) => !sliderItem.dependsOn)
              .map((sliderItem, index) => (
                <div key={index} style={{ marginTop: "16px", marginBottom: "24px" }}>
                  <div className="slider-value">{sliderItem.label}</div>
                  <sliderItem.component
                    values={selectItems[sliderItem.name]}
                    setValues={(values) => setMetricsParamsSlider(name, sliderItem.name, values)}
                  />
                </div>
              ))}
          </div>
          <div className="control-select-btn">
            <div onClick={resetAllParams}>Reset</div>
            <div className="apply" onClick={() => setOpenDropdown(false)}>
              Apply
            </div>
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default MetricsAlert;
