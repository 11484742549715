import moment from "moment";
export const exportSections = () => {
  const optionPages = [
    { lable: "Price Summary", value: "Price summary" },
    { lable: "Promotions Summary", value: "Promotions summary" },
    { lable: "Ranking Summary", value: "Ranking summary" },
    { lable: "Content Summary", value: "Content summary" },
    { lable: "Rating Summary", value: "Rating summary" },
    { lable: "Media Summary", value: "Media summary" },
    { lable: "Navigation Summary", value: "Navigation summary" },
    { lable: "Availability Summary", value: "Availability summary" },
    { lable: "Assortment Summary", value: "Assortment summary" },
  ];

  return [
    {
      name: "section",
      default: "Select Section",
      options: optionPages,
    },
  ];
};

export const sectionNemes = [
  { lable: "Pricing", value: 2, section: "pricing" },
  { lable: "Content", value: 5, section: "content" },
  { lable: "Navigation", value: 8, section: "navigation" },
  { lable: "Promotions", value: 3, section: "promotions" },
  { lable: "Rating", value: 6, section: "rating" },
  { lable: "Availability", value: 9, section: "availability" },
  { lable: "Ranking", value: 4, section: "ranking" },
  { lable: "Media", value: 7, section: "media" },
  { lable: "Assortment", value: 10, section: "assortment" },
];

export const priceNemes = [
  { lable: "Base Price", value: "basePrice" },
  { lable: "Shelf Price", value: "shelfPrice" },
  { lable: "Promoted Price", value: "promotedPrice" },
];

export const viewType = [
  { lable: "View By Retailer", value: "retailer" },
  { lable: "View By Product", value: "product" },
  { lable: "View By Promo Date", value: "promoDate" },
];

export const colourFill = [
  { lable: "View By Mechanic", value: "mechanic" },
  { lable: "View By Retailer", value: "retailer" },
];
export const viewByRetailerType = [
  { lable: "Summary", value: "summary" },
  { lable: "By Manufacturer", value: "byManufacturer" },
  { lable: "By Brand", value: "byBrand" },
];
export const viewByProductType = [
  { lable: "Summary", value: "summary" },
  { lable: "By Retailer", value: "byRetailer" },
];
export const isAllReviews = [
  { lable: "New Reviews within Time Period", value: false },
  { lable: "All Reviews", value: true },
];
export const calculationType = [
  { lable: "Daily View", value: "daily" },
  { lable: "Weekly View", value: "weekly" },
];
export const viewByType = [
  { lable: "By Manufacturer", value: "byManufacturer" },
  { lable: "By Brand", value: "byBrand" },
];
export const viewBy = [
  { lable: "Daily", value: "daily" },
  { lable: "Weekly", value: "weekly" },
  { lable: "Monthly", value: "monthly" },
];
export const organicOrSponsored = [
  { lable: "Include: Sponsored", value: "sponsored" },
  { lable: "Organic rank only", value: "organic" },
];

export const weeklyTypes = {
  name: "weeklyTypes",
  default: "average",
  options: [
    { lable: "Average (Mon to Sun)", value: "average" },
    { lable: "Snapshot", value: "snapshot" },
  ],
};

export const snapshotDays = {
  name: "snapshotDays",
  default: 1,
  options: [
    { lable: "Sunday", value: 0 },
    { lable: "Monday", value: 1 },
    { lable: "Tuesday", value: 2 },
    { lable: "Wednesday", value: 3 },
    { lable: "Thursday", value: 4 },
    { lable: "Friday", value: 5 },
    { lable: "Saturday", value: 6 },
  ],
};

export const sectionsSummary = {
  2: "pricing",
  5: "content",
  8: "navigation",
  3: "promotions",
  6: "rating",
  9: "availability",
  4: "ranking",
  7: "media",
  10: "assortment",
};

export const summaryName = {
  2: "Pricing",
  5: "Content",
  8: "Navigation",
  3: "Promotions",
  6: "Rating",
  9: "Availability",
  4: "Ranking",
  7: "Media",
  10: "Assortment",
};

export const sectionsIds = {
  pricing: 2,
  content: 5,
  navigation: 8,
  promotions: 3,
  rating: 6,
  availability: 9,
  ranking: 4,
  media: 7,
  assortment: 10,
};

export const filtersTemplate = (sourceType, category, manufacture, productBrand, productGroup, product) => [
  {
    name: "sourceType",
    label: "Retailers",
    placeholder: "Retailer",
    nameParam: "label",
    data: sourceType,
  },
  {
    name: "category",
    label: "Categories",
    placeholder: "Category",
    nameParam: "name",
    data: category,
  },
  {
    name: "manufacture",
    label: "Manufacturers",
    placeholder: "Manufacturer",
    nameParam: "name",
    data: manufacture,
  },
  {
    name: "productBrand",
    label: "Brands",
    placeholder: "Brand",
    nameParam: "name",
    data: productBrand,
  },
  {
    name: "productGroup",
    label: "Custom Groups",
    placeholder: "Custom Group",
    nameParam: "name",
    data: productGroup,
  },
  {
    name: "product",
    label: "Products",
    placeholder: "product",
    nameParam: "title",
    data: product,
  },
];

export const getDays = (id, filtersStartDate) => {
  const startDate = filtersStartDate || `${moment().year()}-01-01`;
  const days = {
    2: 1,
    3: 7,
    4: 14,
    5: 30,
    6: 90,
    90: 365,
    91: `custom|${moment(`${moment().year()}-01-01`).format("YYYY-MM-DD")}|${moment(`${moment().year()}-12-31`).format(
      "YYYY-MM-DD"
    )}|ytd`,
    92: `custom|${moment(startDate).format("YYYY-MM-DD")}|${moment(`${moment().year()}-12-31`).format(
      "YYYY-MM-DD"
    )}|ful`,
  };
  return days[id];
};

export const getDayType = (id) => {
  const dayTypes = {
    2: 1,
    3: 7,
    4: 14,
    5: 30,
    6: 90,
    90: 365,
    91: "ytd",
    92: "ful",
  };
  return dayTypes[id];
};
