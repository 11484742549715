import React from "react";
import { Checkbox, Radio, Menu, Dropdown, Button } from "antd";
import { DownOutlined } from "@ant-design/icons";

import SelectCalculation from "components/TabsExport/components/SelectCalculation";
import RadioButtons from "components/TabsExport/components/RadioButtons";

import {
  summaryName,
  priceNemes,
  viewType,
  colourFill,
  viewByRetailerType,
  viewByProductType,
  isAllReviews,
  viewBy,
  organicOrSponsored,
  viewByType,
} from "components/TabsExport/exportSections";

const SUB_OPTION_SYTLE = {
  marginTop: "2px",
  marginLeft: "24px",
};

const ReportOptions = ({ current, sectionId, handleSetOptions, options, amountDays, disabled, isFullHistory }) => {
  const renderOptions = () => {
    switch (sectionId) {
      // pricing
      case 2: {
        return (
          <>
            <RadioButtons
              title={"Select Pricing Mechanic"}
              onChange={handleSetOptions}
              value={options}
              section={"pricing"}
              property={"pricingMechanic"}
              items={priceNemes}
              disabled={disabled}
            />
            <Checkbox
              style={{ marginTop: "24px", fontFamily: "Gilroy-Medium", fontWeight: "bold" }}
              disabled={disabled}
              checked={options["pricing"]["productGroup"]}
              onChange={(e) => handleSetOptions("productGroup", e.target.checked)}
            >
              Include Custom Groups
            </Checkbox>
            <div className="export-hint" style={{ fontFamily: "Gilroy-Medium" }}>
              (Using this view may result in duplicate rows where an individual product exists in multiple custom
              groups)
            </div>

            {amountDays <= 14 ? (
              <div className="export-hint" style={{ marginTop: "24px", fontFamily: "Gilroy-ExtraBold" }}>
                Additional {summaryName[sectionId]} display options (weekly average / snapshot) are available if you
                select a time range greater than 14 days
              </div>
            ) : null}

            {amountDays > 14 ? (
              <SelectCalculation disabled={disabled} options={options["pricing"]} handleSetOptions={handleSetOptions} />
            ) : null}
            <Checkbox
              style={{ marginTop: "24px", fontFamily: "Gilroy-Medium", fontWeight: "bold" }}
              disabled={disabled}
              checked={options["pricing"]["promotion"]}
              onChange={(e) => handleSetOptions("promotion", e.target.checked)}
            >
              Include Promotion Summary in Report
            </Checkbox>
          </>
        );
      }
      // promotions
      case 3: {
        return (
          <>
            <RadioButtons
              title={"Select View Type"}
              onChange={handleSetOptions}
              value={options}
              section={"promotions"}
              property={"viewType"}
              items={viewType}
              disabled={disabled}
            />
            <div>
              <Checkbox
                style={{ marginTop: "24px", fontFamily: "Gilroy-Medium", fontWeight: "bold" }}
                disabled={disabled}
                checked={options["promotions"]["productGroup"]}
                onChange={(e) => handleSetOptions("productGroup", e.target.checked)}
              >
                Include Custom Groups
              </Checkbox>
            </div>
            <div className="export-hint" style={{ fontFamily: "Gilroy-Medium" }}>
              (Using this view may result in duplicate rows where an individual product exists in multiple custom
              groups)
            </div>

            {options["promotions"].viewType !== "promoDate" && (
              <>
                {amountDays <= 14 ? (
                  <div className="export-hint" style={{ marginTop: "24px", fontFamily: "Gilroy-ExtraBold" }}>
                    Additional {summaryName[sectionId]} display options (weekly average / snapshot) are available if you
                    select a time range greater than 14 days
                  </div>
                ) : null}

                <Checkbox
                  style={{ marginTop: "24px", fontFamily: "Gilroy-Medium", fontWeight: "bold" }}
                  disabled={disabled}
                  checked={options["promotions"]["grouped"]}
                  onChange={(e) => handleSetOptions("grouped", e.target.checked)}
                >
                  Group Promotions By Promotion Text
                </Checkbox>

                {amountDays > 14 ? (
                  <SelectCalculation
                    disabled={disabled}
                    options={options["promotions"]}
                    handleSetOptions={handleSetOptions}
                  />
                ) : null}

                {options["promotions"].viewType === "product" ? (
                  <div style={{ marginTop: "24px" }}>
                    <RadioButtons
                      title={"Select Colour Fill"}
                      onChange={handleSetOptions}
                      value={options}
                      section={"promotions"}
                      property={"fillColor"}
                      items={colourFill}
                      disabled={disabled}
                    />
                  </div>
                ) : null}
              </>
            )}
          </>
        );
      }
      // rating
      case 6: {
        return (
          <>
            <div>
              <div className="export-hint" style={{ marginTop: "10px", fontFamily: "Gilroy-ExtraBold" }}>
                Select View Type
              </div>
              <Checkbox
                style={{ marginTop: "12px", fontFamily: "Gilroy-Medium", fontWeight: "bold" }}
                disabled={disabled}
                checked={options["rating"]["hasViewByRetailer"]}
                onChange={(e) => handleSetOptions("hasViewByRetailer", e.target.checked)}
              >
                View By Retailer
              </Checkbox>
              {options["rating"].hasViewByRetailer && (
                <div style={{ marginTop: "12px" }}>
                  <RadioButtons
                    onChange={handleSetOptions}
                    value={options}
                    section={"rating"}
                    property={"viewByRetailerType"}
                    items={viewByRetailerType}
                    disabled={disabled}
                    isVertical={true}
                    styleOverride={SUB_OPTION_SYTLE}
                  />
                </div>
              )}
            </div>
            <div>
              <Checkbox
                style={{ marginTop: "24px", fontFamily: "Gilroy-Medium", fontWeight: "bold" }}
                disabled={disabled}
                checked={options["rating"]["hasViewByProduct"]}
                onChange={(e) => handleSetOptions("hasViewByProduct", e.target.checked)}
              >
                View By Product
              </Checkbox>
              {options["rating"].hasViewByProduct && (
                <div style={{ marginTop: "12px" }}>
                  <RadioButtons
                    onChange={handleSetOptions}
                    value={options}
                    section={"rating"}
                    property={"viewByProductType"}
                    items={viewByProductType}
                    disabled={disabled}
                    isVertical={true}
                    styleOverride={SUB_OPTION_SYTLE}
                  />
                </div>
              )}
            </div>
            <div>
              <Checkbox
                style={{ marginTop: "24px", fontFamily: "Gilroy-Medium", fontWeight: "bold" }}
                disabled={disabled}
                checked={options["rating"]["hasDetailedView"]}
                onChange={(e) => handleSetOptions("hasDetailedView", e.target.checked)}
              >
                Detailed View
              </Checkbox>
              {options["rating"].hasDetailedView && (
                <div style={{ marginTop: "12px", color: "red" }}>
                  {!isFullHistory ? (
                    <div style={{ marginTop: "12px" }}>
                      <RadioButtons
                        onChange={handleSetOptions}
                        value={options}
                        section={"rating"}
                        property={"isAllReviews"}
                        items={isAllReviews}
                        disabled={disabled}
                        isVertical={true}
                        styleOverride={SUB_OPTION_SYTLE}
                      />
                    </div>
                  ) : (
                    <div style={{ marginLeft: "24px", fontFamily: "Gilroy-Medium", fontWeight: "bold" }}>
                      <div style={{ marginTop: "12px", opacity: 0.5 }}>
                        <Radio disabled>New Reviews within Time Period</Radio>
                      </div>
                      <div style={{ marginTop: "12px" }}>
                        <Radio checked={options["rating"]["isAllReviews"]}>All Reviews</Radio>
                      </div>
                    </div>
                  )}
                </div>
              )}
            </div>
            <div style={{ marginTop: "24px" }}>
              <Checkbox
                style={{ fontFamily: "Gilroy-Medium", fontWeight: "bold" }}
                checked={options["rating"]["hasNoReviews"]}
                onChange={(e) => handleSetOptions("hasNoReviews", e.target.checked)}
              >
                View Products with No Reviews
              </Checkbox>
            </div>
            <div>
              <Checkbox
                style={{ marginTop: "24px", fontFamily: "Gilroy-Medium", fontWeight: "bold" }}
                disabled={disabled}
                checked={options["rating"]["productGroup"]}
                onChange={(e) => handleSetOptions("productGroup", e.target.checked)}
              >
                Include Custom Groups
              </Checkbox>
            </div>
            <div className="export-hint" style={{ fontFamily: "Gilroy-Medium" }}>
              (Custom groups is only applicable to the View By Product, Detailed View & View Products With No Reviews
              options)
            </div>
          </>
        );
      }
      // ranking
      case 4: {
        return (
          <>
            <div className="export-hint" style={{ marginTop: "10px", fontFamily: "Gilroy-ExtraBold" }}>
              Select View Type
            </div>
            <div style={{ marginTop: "12px" }}>
              <Checkbox
                style={{ fontFamily: "Gilroy-Medium", fontWeight: "bold" }}
                disabled={disabled}
                checked={options["ranking"]["hasViewByRetailer"]}
                onChange={(e) => handleSetOptions("hasViewByRetailer", e.target.checked)}
              >
                View by Retailer
              </Checkbox>
              {options["ranking"].hasViewByRetailer && (
                <div style={{ marginLeft: "24px" }}>
                  <RadioButtons
                    onChange={handleSetOptions}
                    value={options}
                    section={"ranking"}
                    property={"viewByRetailerValue"}
                    items={viewByType}
                    disabled={disabled}
                    isVertical={true}
                    styleOverride={SUB_OPTION_SYTLE}
                  />
                </div>
              )}
            </div>
            <div style={{ marginTop: "12px" }}>
              <Checkbox
                style={{ fontFamily: "Gilroy-Medium", fontWeight: "bold" }}
                disabled={disabled}
                checked={options["ranking"]["hasViewBySearchTerm"]}
                onChange={(e) => handleSetOptions("hasViewBySearchTerm", e.target.checked)}
              >
                View by Search Term
              </Checkbox>
              {options["ranking"].hasViewBySearchTerm && (
                <div style={{ marginLeft: "24px" }}>
                  <RadioButtons
                    onChange={handleSetOptions}
                    value={options}
                    section={"ranking"}
                    property={"viewBySearchTermValue"}
                    items={viewByType}
                    disabled={disabled}
                    isVertical={true}
                    styleOverride={SUB_OPTION_SYTLE}
                  />
                </div>
              )}
            </div>
            <div style={{ marginTop: "12px" }}>
              <Checkbox
                style={{ fontFamily: "Gilroy-Medium", fontWeight: "bold" }}
                disabled={disabled}
                checked={options["ranking"]["hasViewByRetailerAndSearchTerm"]}
                onChange={(e) => handleSetOptions("hasViewByRetailerAndSearchTerm", e.target.checked)}
              >
                View by Retailer and Search Term
              </Checkbox>
              {options["ranking"].hasViewByRetailerAndSearchTerm && (
                <div style={{ marginLeft: "24px" }}>
                  <RadioButtons
                    onChange={handleSetOptions}
                    value={options}
                    section={"ranking"}
                    property={"viewByRetailerAndSearchTermValue"}
                    items={viewByType}
                    disabled={disabled}
                    isVertical={true}
                    styleOverride={SUB_OPTION_SYTLE}
                  />
                </div>
              )}
            </div>
            <div style={{ marginTop: "24px" }}>
              <RadioButtons
                title={"Organic or sponsored:"}
                onChange={handleSetOptions}
                value={options}
                section={"ranking"}
                property={"organicOrSponsored"}
                items={organicOrSponsored}
                disabled={disabled}
              />
            </div>
            <div style={{ marginTop: "24px" }}>
              <div className="export-hint" style={{ marginTop: "10px", fontFamily: "Gilroy-ExtraBold" }}>
                Include ranks:
              </div>
              <Checkbox
                style={{ fontFamily: "Gilroy-Medium", fontWeight: "bold" }}
                disabled={disabled}
                checked={options["ranking"]["topFive"]}
                onChange={(e) => handleSetOptions("topFive", e.target.checked)}
              >
                Top 5
              </Checkbox>
              <Checkbox
                style={{ fontFamily: "Gilroy-Medium", fontWeight: "bold" }}
                disabled={disabled}
                checked={options["ranking"]["topTen"]}
                onChange={(e) => handleSetOptions("topTen", e.target.checked)}
              >
                Top 10
              </Checkbox>
              <Checkbox
                style={{ fontFamily: "Gilroy-Medium", fontWeight: "bold" }}
                disabled={disabled}
                checked={options["ranking"]["topTwenty"]}
                onChange={(e) => handleSetOptions("topTwenty", e.target.checked)}
              >
                Top 20
              </Checkbox>
              {!options["ranking"]["topFive"] && !options["ranking"]["topTen"] && !options["ranking"]["topTwenty"] && (
                <p>You should select at least one to have the data in report (defaults to top 20 if empty)</p>
              )}
            </div>
            <div style={{ marginTop: "24px" }}>
              <RadioButtons
                title={"View by:"}
                onChange={handleSetOptions}
                value={options}
                section={"ranking"}
                property={"viewBy"}
                items={viewBy}
                disabled={disabled}
              />
            </div>
          </>
        );
      }

      case 5:

      case 8:
      // availability
      case 9:
      //unknown
      case 4:
      // media
      case 7: {
        return (
          <>
            <div className="export-hint" style={{ marginTop: "10px", fontFamily: "Gilroy-ExtraBold" }}>
              Select View Type:
            </div>
            <div style={{ marginTop: "24px" }}>
              <Checkbox
                style={{ fontFamily: "Gilroy-Medium", fontWeight: "bold" }}
                disabled={disabled}
                checked={options["media"]["summaryByRetailer"]}
                onChange={(e) => handleSetOptions("summaryByRetailer", e.target.checked)}
              >
                Summary by retailer
              </Checkbox>
              <Checkbox
                style={{ fontFamily: "Gilroy-Medium", fontWeight: "bold" }}
                disabled={disabled}
                checked={options["media"]["summaryByManufacturer"]}
                onChange={(e) => handleSetOptions("summaryByManufacturer", e.target.checked)}
              >
                Summary by manufacturer
              </Checkbox>
              <Checkbox
                style={{ fontFamily: "Gilroy-Medium", fontWeight: "bold" }}
                disabled={disabled}
                checked={options["media"]["summaryByBrand"]}
                onChange={(e) => handleSetOptions("summaryByBrand", e.target.checked)}
              >
                Summary by brand
              </Checkbox>
            </div>
            <div className="export-hint" style={{ marginTop: "10px", fontFamily: "Gilroy-ExtraBold" }}>
              Include:
            </div>
            <div style={{ marginTop: "24px" }}>
              <Checkbox
                style={{ fontFamily: "Gilroy-Medium", fontWeight: "bold" }}
                disabled={disabled}
                checked={options["media"]["detailView"]}
                onChange={(e) => handleSetOptions("detailView", e.target.checked)}
              >
                Detail view
              </Checkbox>
              <Checkbox
                style={{ fontFamily: "Gilroy-Medium", fontWeight: "bold" }}
                disabled={disabled}
                checked={options["media"]["calendarView"]}
                onChange={(e) => handleSetOptions("calendarView", e.target.checked)}
              >
                Calendar view
              </Checkbox>
            </div>
          </>
        );
      }
      // assortment
      case 10:
      default: {
        return (
          <div style={{ fontSize: "16px", marginTop: "15px", fontFamily: "Gilroy-Bold" }}>
            Report Options are coming soon.
          </div>
        );
      }
    }
  };

  return (
    <>
      <div className="export-item">
        <div className="number">{current}</div>
        <div className="text">Report Options ({summaryName[sectionId]})</div>
      </div>

      {renderOptions()}
    </>
  );
};

export default ReportOptions;
