import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { withRouter } from "react-router-dom";
import * as FileSaver from "file-saver";
import moment from "moment";
import { Button } from "antd";
import { BUTTONS_TYPE } from "constants/buttons-type.constants";

import { downloadQuickReport } from "store/exports/actions";

import { getTimePeriod } from "utils/getTimePeriod";

import LoaderBox from "components/LoaderBox";
import ReportOptions from "components/TabsExport/components/ReportOptions";
import ReportData from "components/TabsExport/components/ReportData";
import useColorTheme from "../../hooks/useColorTheme";
import useCountry from "../../hooks/useCountry";
import useFilters from "hooks/useFilters";

import { sectionsSummary, summaryName, sectionsIds, getDays } from "components/TabsExport/exportSections";
import { STATE_STATUSES } from "utils/statuses";
import MsgPopup from "components/MsgPopup";

const QuickReport = ({ match: { path }, onClose }) => {
  const dispatch = useDispatch();
  const { primaryColor } = useColorTheme();
  const { currentCountry } = useCountry();
  const { mainLastFilter, statusFilters } = useFilters();

  const { sourceType, product, productBrand, manufacture, category, productGroup, date } = mainLastFilter;
  const { filter } = useSelector((state) => state.ratingSummary);
  const {
    settingsYourProfile: {
      company: { filtersStartDate },
    },
  } = useSelector((state) => state.settingsYourProfile);
  const [isShowExportMsg, setIsShowExportMsg] = useState(false);
  const [options, setOptions] = useState({
    pricing: {
      promotion: false,
      pricingMechanic: "shelfPrice",
      viewLogic: "daily",
      weeklyType: "average",
      snapshotDay: 1,
      productGroup: false,
    },
    content: {},
    navigation: false,
    promotions: {
      viewLogic: "daily",
      weeklyType: "average",
      snapshotDay: 1,
      grouped: false,
      viewType: "retailer",
      fillColor: "mechanic",
      productGroup: false,
    },
    rating: {
      hasViewByRetailer: false,
      viewByRetailerType: "summary",
      hasViewByProduct: false,
      viewByProductType: "summary",
      hasDetailedView: false,
      isAllReviews: filter[1].value,
      hasNoReviews: false,
      isSkipNewReviews: filter[1].value,
    },
    availability: {},
    ranking: false,
    media: false,
    assortment: false,
  });

  const [sectionId, setSectionId] = useState("");

  const [amountDays, setAmountDays] = useState(0);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (statusFilters !== STATE_STATUSES.READY) return;
    const currentDate = getTimePeriod(date).split("|");

    const currentDateStart = moment(currentDate[1], "YYYY-MM-DD");
    const currentDateEnd = moment(currentDate[2], "YYYY-MM-DD");

    const amountDays = Math.abs(moment.duration(currentDateStart.diff(currentDateEnd)).asDays());
    setAmountDays(amountDays);
  }, [date, statusFilters]);

  useEffect(() => {
    setSectionId(sectionsIds[path.split("/")[1]]);
  }, [path]);

  const handleSetOptions = (name, value) => {
    setOptions((prevState) => ({
      ...prevState,
      [sectionsSummary[sectionId]]: { ...prevState[sectionsSummary[sectionId]], [name]: value },
    }));
  };

  const download = useCallback(
    (maxRetry, retryNum = 0) => {
      setIsLoading(true);
      dispatch(
        downloadQuickReport({
          options: options[sectionsSummary[sectionId]],
          filters: {
            sourceType,
            product,
            productBrand,
            manufacture,
            category,
            productGroup,
            timePeriod: filter[1].value ? getDays(92, filtersStartDate) : getTimePeriod(date),
            countryId: currentCountry.countryId,
          },
          sectionId,
          primaryColor,
        })
      )
        .then((response) => {
          if (response && response.data && response.data.status !== STATE_STATUSES.READY) {
            setIsShowExportMsg(true);
          } else if (response && response.data && response.data.file) {
            let blob = new Blob([Buffer.from(response.data.file, "base64")], {
              type: "vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8",
            });
            FileSaver.saveAs(blob, `${summaryName[sectionId]} Summary.xlsx`);
          }
          setIsLoading(false);
        })
        .catch(() => {
          if (maxRetry > retryNum) {
            setTimeout(() => download(maxRetry, retryNum + 1), 100);
          }
          setIsLoading(false);
        });
    },
    [
      category,
      currentCountry.countryId,
      date,
      dispatch,
      filter,
      filtersStartDate,
      manufacture,
      options,
      primaryColor,
      product,
      productBrand,
      productGroup,
      sectionId,
      sourceType,
    ]
  );

  const handleExport = useCallback(() => {
    if (statusFilters === STATE_STATUSES.READY) download(0);
  }, [statusFilters, download]);

  const closeModal = () => {
    setIsShowExportMsg(false);
    onClose();
  };

  return (
    <div className="export-tab">
      <div className="export-hint" style={{ fontFamily: "Gilroy-Medium", fontWeight: "bold" }}>
        Run a quick report from the specified module with your current filter selections.
      </div>
      <ReportData current={1} options={options} setSectionId={setSectionId} sectionId={sectionId} disabled={false} />

      {options[sectionsSummary[sectionId]] && (
        <ReportOptions
          current={2}
          sectionId={sectionId}
          handleSetOptions={handleSetOptions}
          options={options}
          amountDays={amountDays}
          isFullHistory={filter[1].value}
        />
      )}

      {options[sectionsSummary[sectionId]] && (
        <Button
          className="export-button"
          onClick={handleExport}
          disabled={Boolean(
            sectionId === 6 &&
              !options.rating.hasViewByRetailer &&
              !options.rating.hasViewByProduct &&
              !options.rating.hasDetailedView &&
              !options.rating.hasNoReviews
          )}
        >
          Download
        </Button>
      )}

      {isLoading && <LoaderBox style={{ height: "120%" }} />}
      <MsgPopup
        themeColor={primaryColor}
        buttonType={BUTTONS_TYPE.SOLID}
        onOk={closeModal}
        onCancel={closeModal}
        isShow={isShowExportMsg}
        title={"Your report is being prepared"}
        message={"Your file will download automatically when ready"}
      />
    </div>
  );
};

export default withRouter(QuickReport);
