import axios from "axios";
import { createRequestInstance, watchRequests } from "redux-saga-requests";
import { createDriver } from "redux-saga-requests-axios";
import { store } from "store";
import { logOutRequest } from "store/authorization/actions";

const instance = axios.create({
  timeout: 10000000,
  headers: axios.defaults.headers,
});

instance.interceptors.request.use(function (config) {
  const token = localStorage.getItem("Token") ? JSON.parse(localStorage.getItem("Token")) : "";
  config.headers.Authorization = token;

  return config;
});

instance.interceptors.response.use(
  (response) => response,
  (error) => {
    const status = error.response && error.response.status;
    if (status === 403) {
      localStorage.removeItem("Token");
      localStorage.removeItem("RefreshToken");
      store.dispatch(logOutRequest());
    }
    return Promise.reject(error);
  }
);

export default function* rootSaga() {
  yield createRequestInstance({ driver: createDriver(instance) });
  yield watchRequests({ takeLatest: true });
}
