import React from "react";
import { Slider as AntdSlider } from "antd";

const Slider = ({ values, setValues }) => {
  return (
    <div>
      <AntdSlider min={1} max={100} value={values} onChange={setValues} />
      <div className="wrapper-slider-inputs">
        <input
          type="text"
          disabled={false}
          value={values}
          onChange={(e) => {
            const inputValue = Number(e.target.value);
            if (isNaN(inputValue)) {
              setValues(0);
            } else if (inputValue > 100) {
              setValues(100);
            } else {
              setValues(inputValue);
            }
          }}
        />
      </div>
    </div>
  );
};

export default Slider;
