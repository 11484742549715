import React, { useEffect, useState } from "react";
import { Slider } from "antd";

const RangeSlider = ({ values, setValues }) => {
  const handleInputChange = (index, event) => {
    const inputValue = event.target.value.trim() === "" ? "" : Number(event.target.value);

    if (inputValue === "" || isNaN(inputValue)) {
      return;
    }

    let newValue = [...values];

    if (index === 0) {
      newValue[0] = Math.min(Math.max(inputValue, 0), newValue[1]);
    } else {
      newValue[1] = Math.max(Math.min(inputValue, 100), newValue[0]);
    }

    setValues(newValue);
  };

  return (
    <div>
      <Slider range min={0} max={100} value={values} onChange={setValues} />
      <div className="wrapper-slider-inputs">
        <input type="text" value={values[0]} onChange={(e) => handleInputChange(0, e)} />
        <div className="separate">-</div>
        <input type="text" value={values[1]} onChange={(e) => handleInputChange(1, e)} />
      </div>
    </div>
  );
};

export default RangeSlider;
