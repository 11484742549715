import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import Maintenance from "./pages/Maintenance/Maintenance";
import * as serviceWorker from "./serviceWorker";

import Routes from "./Routes";
import { Provider } from "react-redux";
import { store } from "./store";

const isMaintenanceMode = process.env.REACT_APP_MAINTENANCE === "true";

ReactDOM.render(
  <Provider store={store}>
    {isMaintenanceMode ? (
      <Maintenance />
    ) : (
      <App>
        <Routes />
      </App>
    )}
  </Provider>,
  document.getElementById("root")
);

serviceWorker.unregister();
