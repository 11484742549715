import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button } from "antd";
import useCountry from "../../hooks/useCountry";
import { setSelectAllProducts, setSelectAllBrands } from "store/exports/actions";
import {
  fetchActiveCompanyMembers,
  fetchExportFilters,
  editSavedReport,
  fetchSavedReport,
  clearSavedReport,
} from "store/exports/actions";

import TemplateReport from "components/TabsExport/components/TemplateReport";

import backIcn from "assets/images/back.svg";

import { sectionsSummary } from "components/TabsExport/exportSections";

const EditReport = ({ id, setReportId }) => {
  const dispatch = useDispatch();

  const { savedReport, savedReports, companyMembers } = useSelector((state) => state.exports);

  const {
    filters: { filters },
    selectAllProducts,
    selectAllBrands,
  } = useSelector((state) => state.exports);
  const { currentCountry } = useCountry();

  const [disabled, setDisabled] = useState(false);

  const [sectionId, setSectionId] = useState("");

  const [name, setName] = useState(null);
  const [options, setOptions] = useState({
    pricing: {
      promotion: false,
      pricingMechanic: "shelfPrice",
      viewLogic: "daily",
      weeklyType: "average",
      snapshotDay: 1,
      productGroup: false,
    },
    content: {},
    navigation: {},
    promotions: {
      viewLogic: "daily",
      weeklyType: "average",
      snapshotDay: 1,
      grouped: false,
      viewType: "retailer",
      fillColor: "mechanic",
      productGroup: false,
    },
    rating: {},
    availability: {},
    ranking: {
      hasViewByRetailer: false,
      viewByRetailerValue: "byManufacturer",
      hasViewBySearchTerm: false,
      viewBySearchTermValue: "byManufacturer",
      hasViewByRetailerAndSearchTerm: false,
      viewByRetailerAndSearchTermValue: "byManufacturer",
      viewBy: "daily",
      organicOrSponsored: "sponsored",
      topFive: false,
      topTen: false,
      topTwenty: true,
    },
    media: {
      summaryByRetailer: false,
      summaryByManufacturer: false,
      summaryByBrand: false,
      detailView: false,
      calendarView: false,
    },
    assortment: {},
  });
  const [filterParams, setFilterParams] = useState(null);
  const [schedule, setSchedule] = useState(null);

  const [emails, setEmails] = useState(null);
  const [users, setUsers] = useState(null);

  const getFilterArray = (string) => {
    return string
      .split("|")
      .map((item) => Number(item))
      .filter((item) => item);
  };

  useEffect(() => {
    const matchedItem = savedReports.find((item) => item.id === id);
    let disabled;
    if (matchedItem.sharedReport) {
      disabled = matchedItem.userId !== matchedItem.sharedReport.userId;
    } else {
      disabled = false;
    }
    setDisabled(disabled);
  }, [id, savedReports]);

  useEffect(() => {
    dispatch(fetchActiveCompanyMembers());
    dispatch(fetchSavedReport(id)).then((res) => {
      const { data, name, emails, users, sectionId, schedule } = res.data;
      setName(name);
      setOptions((prevState) => ({
        ...prevState,
        [sectionsSummary[sectionId]]: data.options,
      }));
      setSchedule(schedule);
      setEmails(emails.length ? emails : [""]);
      setUsers(users);
      setSectionId(sectionId);

      const getData = (data) =>
        data
          .split("|")
          .filter((item) => Number(item))
          .map((item) => Number(item));

      const params = {
        sourceType: getData(data.filters.sourceType),
        category: getData(data.filters.category),
        manufacture: getData(data.filters.manufacture),
        product: getData(data.filters.product),
        productBrand: getData(data.filters.productBrand),
        productGroup: getData(data.filters.productGroup),
        timePeriod: data.filters.timePeriod,
        save: false,
      };

      setFilterParams(params);

      dispatch(
        fetchExportFilters({
          ...{
            sourceType: data.filters.sourceType,
            category: data.filters.category,
            manufacture: data.filters.manufacture,
            product: data.filters.product,
            productBrand: data.filters.productBrand,
            productGroup: data.filters.productGroup,
          },
          ...{ watchlist: false },
        })
      );
    });
  }, [id]);

  useEffect(() => {
    return () => {
      dispatch(clearSavedReport());
    };
  }, []);

  const handleSetOptions = (name, value) => {
    setOptions((prevState) => ({
      ...prevState,
      [sectionsSummary[sectionId]]: { ...prevState[sectionsSummary[sectionId]], [name]: value },
    }));
  };

  const setScheduleType = (value) => {
    setSchedule((prevState) => ({
      ...prevState,
      ["type"]: value,
    }));
  };

  const setScheduleDays = (value) => {
    if (schedule.days.includes(value)) {
      setSchedule((prevState) => ({
        ...prevState,
        ["days"]: prevState["days"].filter((item) => item !== value),
      }));
    } else {
      setSchedule((prevState) => ({
        ...prevState,
        ["days"]: [...prevState["days"], value],
      }));
    }
  };

  const handleChangeInput = (value, indexVal) => {
    const values = emails.map((item, index) => {
      if (index === indexVal) {
        return (item = value);
      }
      return item;
    });

    setEmails(values);
  };

  const handleAddInput = () => {
    setEmails((prevState) => [...prevState, ""]);
  };

  const handleDeleteInput = (indexDel) => {
    const inputs = emails.filter((item, index) => index !== indexDel);
    setEmails(inputs);
  };

  const handleSetMembers = (id) => {
    if (users.includes(id)) {
      const selected = users.filter((item) => item !== id);
      setUsers(selected);
    } else {
      setUsers((prevState) => [...prevState, id]);
    }
  };

  const queryParams = () => ({
    sourceType: filterParams.sourceType.join("|"),
    productBrand: filterParams.productBrand.includes("All") ? "All" : filterParams.productBrand.join("|"),
    category: filterParams.category.join("|"),
    manufacture: filterParams.manufacture.join("|"),
    productGroup: filterParams.productGroup.join("|"),
    product: filterParams.product.includes("All") ? "All" : filterParams.product.join("|"),
    save: false,
    watchlist: false,
  });

  const setFiltersParams = (name, selectItems) => {
    const sendParams = {
      ...queryParams(),
      ...{ [name]: Array.isArray(selectItems) ? selectItems.join("|") : selectItems },
    };

    if (name === "product") {
      dispatch(setSelectAllProducts(selectItems.includes("All")));
      if (selectItems.includes("All")) {
        setFilterParams((prevState) => ({ ...prevState, product: ["All"] }));
        return;
      }
    }

    if (name === "productBrand") {
      dispatch(setSelectAllBrands(selectItems.includes("All")));
      if (selectItems.includes("All")) {
        dispatch(setSelectAllProducts(selectItems.includes("All"))); // Update related state
        setFilterParams((prevState) => ({ ...prevState, productBrand: ["All"] }));
      }
    }

    dispatch(fetchExportFilters(sendParams)).then((res) => {
      const filterNames = ["sourceType", "category", "manufacture", "productBrand", "productGroup", "product"];
      const { filters } = res.data;

      // Update only the relevant filterParams, ensuring that "All" isn't overwritten
      filterNames.forEach((filterName) => {
        if (filterName === name) {
          const currentFilters = Array.isArray(filters[filterName])
            ? filters[filterName].map((item) => item.id)
            : Object.values(filters[filterName])
                .flat()
                .map((item) => item.id);

          const selectedItems = getFilterArray(sendParams[filterName]).filter((item) => currentFilters.includes(item));

          setFilterParams((prevState) => ({
            ...prevState,
            [filterName]: selectedItems,
          }));
        }
      });
    });
  };

  const setFiltersParamsDate = (timePeriod) => {
    let isSkipNewReviews = false;
    if (isNaN(timePeriod)) {
      const timeSelected = timePeriod.split("|");
      if (timeSelected.length > 3 && timeSelected[3] === "ful") {
        handleSetOptions("isAllReviews", true);
        isSkipNewReviews = true;
      }
    }
    handleSetOptions("isSkipNewReviews", isSkipNewReviews);

    setFilterParams((prevState) => ({
      ...prevState,
      timePeriod,
    }));
  };

  const isArray = (value) => Array.isArray(value);
  const handleEdit = () => {
    const productFilter = selectAllProducts
      ? "All"
      : isArray(filterParams.product)
      ? filterParams.product.join("|")
      : filterParams.product;
    const brandFilter = selectAllBrands
      ? "All"
      : isArray(filterParams.productBrand)
      ? filterParams.productBrand.join("|")
      : filterParams.productBrand;
    dispatch(
      editSavedReport(id, {
        name,
        schedule,
        sectionId,
        options: options[sectionsSummary[sectionId]],
        filters: {
          ...queryParams(),
          ...{ timePeriod: filterParams.timePeriod },
          product: productFilter,
          productBrand: brandFilter,
          countryId: currentCountry.countryId,
        },
        share: {
          users,
          emails: emails[0].length ? emails : [],
        },
      })
    ).then(() => setReportId(null));
  };

  return (
    <>
      {Object.entries(savedReport).length && options && filterParams && schedule && emails && users && sectionId ? (
        <>
          <div className="back-btn-box" onClick={() => setReportId(null)}>
            <div>
              <img src={backIcn} />
            </div>
            <span>Back</span>
          </div>
          <TemplateReport
            setFiltersParamsDate={setFiltersParamsDate}
            setFiltersParams={setFiltersParams}
            handleSetMembers={handleSetMembers}
            handleDeleteInput={handleDeleteInput}
            handleAddInput={handleAddInput}
            handleChangeInput={handleChangeInput}
            setScheduleDays={setScheduleDays}
            setScheduleType={setScheduleType}
            handleSetOptions={handleSetOptions}
            name={name}
            setName={setName}
            filterParams={filterParams}
            filters={filters}
            setSectionId={setSectionId}
            sectionId={sectionId}
            options={options}
            companyMembers={companyMembers}
            users={users}
            schedule={schedule}
            emails={emails}
            disabled={disabled}
            isEditing={true}
          />

          {Boolean(options[sectionsSummary[sectionId]]) &&
          Object.entries(options[sectionsSummary[sectionId]]).length ? (
            <Button
              disabled={disabled || !Boolean(name) || !Boolean(name.trim())}
              style={{ marginTop: "30px" }}
              className="export-button"
              onClick={handleEdit}
            >
              Update
            </Button>
          ) : null}
        </>
      ) : null}
    </>
  );
};

export default EditReport;
